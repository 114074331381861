import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, isDevMode, NgModule } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent, PROGRESS_ID } from './app.component';
import { ProfileModule } from './profile/profile.module';
import { AuthenticationModule } from './authentication/authentication.module';
import { SharedModule } from './shared/shared.module';
import { SettingsModule } from './settings/settings.module';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { reducers } from './shared/app.reducers';
import { ServiceWorkerModule } from '@angular/service-worker';
import { InitializerService } from './initializer.service';
import { SsoModule } from './sso/sso.module';
import { NavItemModule } from '@wam/component-library/nav-item';
import { ButtonModule } from '@wam/component-library/button';
import { FunctionalButtonModule } from '@wam/component-library/functional-button';
import { NotificationsModule } from '@wam/notifications';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguageHeaderInterceptor } from './profile/language-header.interceptor';
import { Angulartics2Module } from 'angulartics2';
import { MentorFormsModule } from './mentor-forms/mentor-forms.module';
import { LiveChatWidgetModule } from '@livechat/widget-angular';
import { ConfigurationModule } from './configuration/configuration.module';
import { IconBadgeModule } from '@wam/component-library/icon-badge';
import { MessagesAndNotificationsStateModule } from './messages-and-notifications/messages-and-notifications-state.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { GoalsEffects } from './goals/state/goals.effects';
import { IconModule } from '@wam/icons';
import { IconButtonModule } from '@wam/component-library/icon-button';
import { ThemingInitializerService } from '@app/theming-initializer.service';
import { MatSidenavModule } from '@angular/material/sidenav';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollToTopModule } from '@app/shared/scroll-to-top/scroll-to-top.module';
import { RefreshTokenInterceptor } from '@app/refresh-token.interceptor';
import * as Sentry from '@sentry/angular-ivy';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { SettingsService } from '@app/settings/settings.service';
import { HelpWidgetModule } from './shared/help-widget/help-widget.module';
import { EnrollmentDialogsModule } from './programs/enrollment-dialogs/enrollment-dialogs.module';
import { LogoutConfirmDialogModule } from '@app/shared/logout-confirm-dialog/logout-confirm-dialog.module';
import { DefaultLayoutComponent } from '@app/layouts/default-layout/default-layout.component';
import { StudentRedesignLayoutComponent } from '@app/layouts/student-redesign-layout/student-redesign-layout.component';
import { UpstartNetflixLayoutComponent } from '@app/layouts/upstart-netflix-layout/upstart-netflix-layout.component';
import { UpstartLoginLayoutComponent } from '@app/layouts/upstart-login-layout/upstart-login-layout.component';
import { FooterComponent } from '@app/layouts/footer/footer.component';
import * as authenticationActions from '@app/authentication/state/authentication.actions';
import { SnowplowInitializerService } from '@app/snowplow-initializer.service';
import { WelcomeUpstartDialogModule } from '@app/shared/welcome-upstart-dialog/welcome-upstart-dialog.module';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

// see https://stackoverflow.com/questions/39323285/how-to-reset-all-states-of-ngrx-store/45324038#45324038
function resetNgRxState(reducer) {
  return function (state, action) {
    return reducer(action === authenticationActions.clearNgRxStore ? undefined : state, action);
  };
}

@NgModule({
  declarations: [
    AppComponent,
    DefaultLayoutComponent,
    StudentRedesignLayoutComponent,
    UpstartNetflixLayoutComponent,
    UpstartLoginLayoutComponent,
  ],
  imports: [
    MatSnackBarModule,
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    HttpClientModule,
    SharedModule,
    ProfileModule,
    MentorFormsModule,
    SsoModule,
    EnrollmentDialogsModule,
    StoreModule.forRoot(reducers, {
      metaReducers: [resetNgRxState],
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([GoalsEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 50,
      logOnly: !isDevMode(),
      connectInZone: true,
    }),
    AuthenticationModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: !isDevMode() }),
    NgProgressModule.withConfig({
      spinner: false,
      color: '#2E94B2',
      thick: true,
    }),
    NgProgressHttpModule.withConfig({
      id: PROGRESS_ID,
    }),
    SettingsModule,
    BrowserAnimationsModule,
    NavItemModule,
    ButtonModule,
    FunctionalButtonModule,
    NotificationsModule.forRoot({
      timeout: 4000,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    Angulartics2Module.forRoot(),
    LiveChatWidgetModule,
    ConfigurationModule,
    IconBadgeModule,
    MessagesAndNotificationsStateModule,
    IconModule,
    IconButtonModule,
    MatSidenavModule,
    PortalModule,
    ScrollToTopModule,
    RecaptchaV3Module,
    HelpWidgetModule,
    LogoutConfirmDialogModule,
    FooterComponent,
    WelcomeUpstartDialogModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (traceService) => () => traceService,
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (service: InitializerService) => () => service.load(),
      multi: true,
      deps: [InitializerService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (service: ThemingInitializerService) => () => service.initialize(),
      multi: true,
      deps: [ThemingInitializerService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (service: SnowplowInitializerService) => () => service.initialize(),
      multi: true,
      deps: [SnowplowInitializerService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageHeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
    {
      provide: 'googleTagManagerId',
      useValue: 'GTM-546MJWN',
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useFactory: (service: SettingsService) => service.settings?.recaptcha?.siteKey,
      deps: [SettingsService],
    },
    provideAnimations(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
