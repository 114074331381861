<div class="profile">
  <div
    class="profile__title"
    id="profile-title">
    {{ 'Profile' | translate }}
  </div>
  <div class="profile__name">
    {{ name$ | async }}
  </div>
  <div class="profile__select">
    <div class="profile__select__label">
      {{ 'Language:' | translate }}
    </div>
    <div class="profile__select__type">
      <div class="profile__select__type__wrapper">
        <label>
          <select
            #languageSelect
            (change)="toggleLanguage(languageSelect.value); $event.preventDefault()"
            [ngModel]="language$ | async"
            class="profile__select__type__select"
            id="select-language">
            <option value="en">{{ 'English' | translate }}</option>
            <option value="es">{{ 'Spanish' | translate }}</option>
            <option value="ro">{{ 'Romanian' | translate }}</option>
          </select>
        </label>
      </div>
    </div>
  </div>
  <div class="profile__tab-position">
    <div class="profile__tab-position__label">
      {{ 'Tab position:' | translate }}
    </div>
    <label for="top">
      <input
        #top
        (click)="toggleTabPosition('top')"
        [checked]="(tabPosition$ | async) === 'top'"
        id="top"
        name="tab"
        type="radio"
        value="top" />
      <span
        [class.selected]="top.checked"
        class="profile__tab-position--top">
        <mat-icon>arrow_upward</mat-icon>
      </span>
    </label>
    <label for="left">
      <input
        #left
        (click)="toggleTabPosition('left')"
        [checked]="(tabPosition$ | async) === 'left'"
        id="left"
        name="tab"
        type="radio"
        value="left" />
      <span
        [class.selected]="left.checked"
        class="profile__tab-position--left">
        <mat-icon>arrow_back</mat-icon>
      </span>
    </label>
  </div>
  <div
    class="profile__select"
    *ngIf="isUpstartUser$ | async">
    <div class="profile__select__label">
      {{ 'Account Type:' | translate }}
    </div>
    <div
      class="profile__select__type"
      *ngIf="!isLoadingAccountType">
      <div class="profile__select__type__wrapper">
        <label>
          <select
            #profileSelect
            *ngIf="isUpstartUser$ | async"
            (change)="changeUpstartAccountType(profileSelect.value); $event.preventDefault()"
            [ngModel]="upstartAccountType$ | async"
            [disabled]="!(upstartAccountType$ | async) || hasAppliedToProgramEnrollments"
            class="profile__select__type__select"
            id="select-account-type">
            <option *ngIf="profileSelect.disabled"></option>
            <option value="care-center">{{ 'Child Care Center' | translate }}</option>
            <option value="family">{{ 'Family' | translate }}</option>
          </select>
        </label>
      </div>
    </div>
    <mat-progress-spinner
      *ngIf="isLoadingAccountType"
      class="loading-spinner"
      diameter="33"
      mode="indeterminate"></mat-progress-spinner>
  </div>
  <cl-button
    (click)="profileService.openContactUsDialog()"
    *ngIf="isParent$ | async">
    {{ 'Contact us' | translate }}
  </cl-button>
  <ng-container *ngIf="shouldShowPin$ | async">
    <div class="profile__pin">
      <div class="profile__pin__label">
        {{ 'Teacher Tools PIN:' | translate }}
      </div>
      <code-input
        #code
        [disabled]="pinDisabled"
        [code]="currentPin"
        (codeCompleted)="codeCompleted($event)"
        (codeChanged)="codeChanged()"></code-input>
      <cl-help-button
        [icon]="Icon.info"
        expandTo="bottom">
        <div help>
          {{
            "The PIN is used to access the settings menu during a student's session. This allows you to skip or" +
              ' restart an activity, report a problem or exit a session but prevents students from doing so.'
              | translate
          }}
        </div>
      </cl-help-button>
    </div>
    <div class="actions">
      <cl-button
        (click)="savePin()"
        [disabled]="!currentPin || !pinChanged"
        id="save-button">
        {{ 'Save' | translate }}
      </cl-button>
      <cl-button
        (click)="cancelPin()"
        buttonStyle="secondary"
        [disabled]="!pinChangedOrEditing"
        id="cancel-button">
        {{ 'Cancel' | translate }}
      </cl-button>
    </div>
  </ng-container>
  <iframe
    class="profile__request__frame"
    id="requestTarget"
    name="requestTarget"
    src=""
    title="Request target"></iframe>
</div>
