import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import {
  State,
  User,
  UserRole,
  UserType,
  configurationSelectors,
  settingsSelectors,
} from '@wam/shared';
import { authenticationSelectors } from '@wam/authentication';
import { map, mergeMap, take, tap } from 'rxjs/operators';
import { ProfileService } from './profile.service';
import { isParent } from '@app/authentication/user.data';
import { Icon } from '@wam/icons';
import { CodeInputComponent } from '@kokotree-inc/angular-code-input';
import { isEmpty } from 'lodash-es';
import {
  EnrollmentWizardDialogService,
  UpstartAccountType,
} from '@app/programs/enrollment-dialogs/enrollment-wizard-dialog.service';
import { ProgramsClaimsService } from '@app/programs/programs-claims/programs-claims.service';
import * as configurationActions from '@app/configuration/state/configuration.actions';
import { ProgramsDataService } from '@app/programs/programs-data.service';
import { PROVIDER_FORM_ID } from '@app/constants';
import * as profileActions from '@app/profile/state/profile.actions';
import { SnowplowService } from '@app/shared/snowplow.service';

@Component({
  selector: 'profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  Icon = Icon;
  tabPosition$ = this.store.select(
    configurationSelectors.getUserConfiguration<'top' | 'left'>('tabPosition'),
  );
  language$ = this.store.select(configurationSelectors.getUserConfiguration<string>('language'));
  upstartAccountType$ = this.store.select(
    configurationSelectors.getUserConfiguration<string>('upstartAccountType'),
  );
  name$: Observable<string> = this.store.select(authenticationSelectors.getName);
  isParent$: Observable<boolean> = this.store.pipe(
    select(authenticationSelectors.getCurrentUser),
    map((user: User) => isParent(user)),
  );
  isUpstartUser$ = this.store.pipe(
    select(settingsSelectors.getUserType),
    map((userType) => userType === UserType.UPSTART),
  );
  shouldShowPin$: Observable<boolean> = this.store.pipe(
    select(authenticationSelectors.getCurrentUser),
    map(
      ({ type, roles }) =>
        type === UserType.TEACHER &&
        !(roles.includes(UserRole.SCHOOL_ADMIN) || roles.includes(UserRole.DISTRICT_ADMIN)),
    ),
  );

  isLoadingAccountType = true;
  hasAppliedToProgramEnrollments = false;

  constructor(
    private store: Store<State>,
    public profileService: ProfileService,
    public programsService: ProgramsDataService,
    private programsClaimsService: ProgramsClaimsService,
    private enrollmentWizardDialogService: EnrollmentWizardDialogService,
    private snowplowService: SnowplowService,
  ) {}

  toggleLanguage(language: string): void {
    this.profileService.toggleLanguage(language);
  }

  toggleTabPosition(tabPosition: 'top' | 'left'): void {
    this.profileService.toggleTabPosition(tabPosition);
  }

  changeUpstartAccountType(upstartAccountType: string): void {
    if (upstartAccountType === UpstartAccountType.FAMILY) {
      this.programsClaimsService
        .getSubmissionsByFormId(PROVIDER_FORM_ID)
        .pipe(
          mergeMap((form) =>
            this.programsService.deleteSubmission(
              PROVIDER_FORM_ID,
              form[0].formVersion,
              form[0].id,
            ),
          ),
          tap(() =>
            this.store.dispatch(
              configurationActions.saveUserConfiguration({
                key: 'upstartAccountType',
                value: upstartAccountType,
              }),
            ),
          ),
        )
        .subscribe();
    } else if (upstartAccountType === UpstartAccountType.CARE_CENTER) {
      this.enrollmentWizardDialogService
        .openFacilityInfoDialog()
        .afterClosed()
        .pipe(
          tap((success) => {
            if (success) {
              this.store.dispatch(
                configurationActions.saveUserConfiguration({
                  key: 'upstartAccountType',
                  value: upstartAccountType,
                }),
              );
            }
          }),
        )
        .subscribe();
    }
  }

  initialPin: string = null;
  currentPin: string = null;
  editingPin: boolean = false;
  @ViewChild('code') codeInput: CodeInputComponent;
  pinDisabled = false;

  ngOnInit(): void {
    this.shouldShowPin$.pipe(take(1)).subscribe((shouldShowPin) => {
      if (shouldShowPin) {
        this.loadPin();
      }
    });
    this.isUpstartUser$.pipe(take(1)).subscribe((isUpstartUser) => {
      if (isUpstartUser) {
        this.profileService.getProgramEnrollments().subscribe((programEnrollments) => {
          if (!isEmpty(programEnrollments)) {
            this.hasAppliedToProgramEnrollments = true;
          }
          this.isLoadingAccountType = false;
        });
      }
    });
    this.snowplowService.trackPageView();
  }

  loadPin(): void {
    this.pinDisabled = true;
    this.store
      .select(configurationSelectors.getUserConfiguration('teacherPIN'))
      .subscribe((pin: string) => {
        this.currentPin = pin;
        this.initialPin = this.currentPin;
        this.pinDisabled = false;
      });
  }

  savePin(): void {
    this.store.dispatch(profileActions.updateTeacherPIN({ pin: this.currentPin }));
    this.initialPin = this.currentPin;
  }

  cancelPin(): void {
    this.currentPin = this.initialPin;
    this.editingPin = false;
    this.codeInput.reset();
  }

  get pinChanged(): boolean {
    return this.currentPin !== this.initialPin;
  }

  get pinChangedOrEditing(): boolean {
    return this.pinChanged || this.editingPin;
  }

  codeCompleted(pin: string): void {
    this.currentPin = pin;
    this.editingPin = false;
  }

  codeChanged(): void {
    this.editingPin = true;
  }

  protected readonly UpstartAccountType = UpstartAccountType;
}
