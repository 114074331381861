import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { Tabs } from './shared/tabs.enum';

import { ProfileComponent } from './profile/profile.component';
import { AuthenticationGuard } from './authentication/authentication.guard';
import { StudentGuard } from './authentication/student.guard';
import { ParentGuard } from './authentication/parent.guard';
import { LoginComponent } from './authentication/login.component';
import { LogoutComponent } from './authentication/logout.component';
import { LoggedInComponent } from './authentication/logged-in.component';
import { StaffGuard } from './authentication/staff.guard';
import { AdminGuard } from './authentication/admin.guard';
import { mentorFormsRoutes } from './mentor-forms/mentor-forms-routes.routes';
import { SignupComponent } from './authentication/signup.component';
import { InternalAdminGuard } from './authentication/internal-admin.guard';
import { InternalAdminOrCurriculumAdminGuard } from './authentication/internal-admin-or-curriculum-admin.guard';
import { AccountTabAdminGuard } from './authentication/account-tab-admin.guard';
import { UpstartParentGuard } from '@app/authentication/upstart-parent.guard';
import { InternalAdminOrAccountAdminOrSupportEngineerGuard } from './authentication/internal-admin-or-account-admin-or-support-engineer-guard.service';
import { RedesignedStudentGuard } from '@app/authentication/redesigned-student.guard';

const routes: Routes = [
  {
    path: 'accounts',
    loadChildren: () => import('./accounts/accounts.module').then((m) => m.AccountsModule),
    data: {
      tab: Tabs.ACCOUNTS,
    },
    canLoad: [AuthenticationGuard, AccountTabAdminGuard],
    canActivate: [AuthenticationGuard, AccountTabAdminGuard],
  },
  {
    path: 'achievements',
    loadChildren: () =>
      import('./achievements/achievements.module').then((m) => m.AchievementsModule),
    data: {
      tab: Tabs.ACHIEVEMENTS,
    },
    canLoad: [AuthenticationGuard, ParentGuard],
    canActivate: [AuthenticationGuard, ParentGuard],
  },
  {
    path: 'programs',
    loadChildren: () => import('./programs/programs.module').then((m) => m.ProgramsModule),
    data: {
      tab: Tabs.PROGRAMS,
    },
    canLoad: [AuthenticationGuard, ParentGuard],
    canActivate: [AuthenticationGuard, ParentGuard],
  },
  {
    path: 'activities',
    loadChildren: () => import('./activities/activities.module').then((m) => m.ActivitiesModule),
    data: {
      tab: Tabs.ACTIVITIES,
    },
    canLoad: [AuthenticationGuard],
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'activity-designer',
    loadChildren: () =>
      import('./activity-designer/activity-designer.module').then((m) => m.ActivityDesignerModule),
    data: {
      tab: Tabs.ACTIVITY_DESIGNER,
    },
    canLoad: [AuthenticationGuard, InternalAdminOrCurriculumAdminGuard],
    canActivate: [AuthenticationGuard, InternalAdminOrCurriculumAdminGuard],
  },
  {
    path: 'assessment-designer',
    loadChildren: () =>
      import('./assessment-designer/assessment-designer.module').then(
        (m) => m.AssessmentDesignerModule,
      ),
    data: {
      tab: Tabs.ASSESSMENT_DESIGNER,
    },
    canLoad: [AuthenticationGuard, InternalAdminOrCurriculumAdminGuard],
    canActivate: [AuthenticationGuard, InternalAdminOrCurriculumAdminGuard],
  },
  {
    path: 'admin-home',
    loadChildren: () => import('./admin-home/admin-home.module').then((m) => m.AdminHomeModule),
    data: {
      tab: Tabs.ADMIN_HOME,
    },
    canLoad: [AuthenticationGuard, AdminGuard],
    canActivate: [AuthenticationGuard, AdminGuard],
  },
  {
    path: 'component-library',
    loadChildren: () => import('@wam/component-library').then((m) => m.ComponentLibraryModule),
    data: {
      tab: Tabs.LOGIN,
    },
  },
  {
    path: 'configuration',
    loadChildren: () =>
      import('./configuration-management/configuration-management.module').then(
        (m) => m.ConfigurationManagementModule,
      ),
    data: {
      tab: Tabs.CONFIGURATION,
    },
    canLoad: [AuthenticationGuard, InternalAdminGuard],
    canActivate: [AuthenticationGuard, InternalAdminGuard],
  },
  {
    path: 'dashboards',
    loadChildren: () => import('./dashboards/dashboards.module').then((m) => m.DashboardsModule),
    data: {
      tab: Tabs.DASHBOARDS,
    },
    canLoad: [AuthenticationGuard, ParentGuard],
    canActivate: [AuthenticationGuard, ParentGuard],
  },
  {
    path: 'designer',
    loadChildren: () => import('./designer/designer.module').then((m) => m.DesignerModule),
    data: {
      tab: Tabs.DESIGNER,
    },
    canLoad: [AuthenticationGuard, InternalAdminOrCurriculumAdminGuard],
    canActivate: [AuthenticationGuard, InternalAdminOrCurriculumAdminGuard],
  },
  {
    path: 'eula',
    loadChildren: () => import('./eula/eula.module').then((m) => m.EulaModule),
    data: {
      tab: Tabs.LOGIN,
    },
  },
  {
    path: 'goals',
    loadChildren: () => import('./goals/goals.module').then((m) => m.GoalsModule),
    data: {
      tab: Tabs.GOALS,
    },
    canLoad: [AuthenticationGuard, StaffGuard],
    canActivate: [AuthenticationGuard, StaffGuard],
  },
  {
    path: 'inbox',
    loadChildren: () => import('./inbox/inbox.module').then((m) => m.InboxModule),
    data: {
      tab: Tabs.INBOX,
    },
    canLoad: [AuthenticationGuard],
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'families',
    loadChildren: () => import('./families/families.module').then((m) => m.FamiliesModule),
    data: {
      tab: Tabs.FAMILIES,
    },
    canLoad: [AuthenticationGuard, InternalAdminOrAccountAdminOrSupportEngineerGuard],
    canActivate: [AuthenticationGuard, InternalAdminOrAccountAdminOrSupportEngineerGuard],
  },
  {
    path: 'landing',
    loadChildren: () => import('./landing/landing.module').then((m) => m.LandingModule),
    data: {
      tab: Tabs.LANDING,
    },
  },
  {
    path: 'logged-in',
    component: LoggedInComponent,
    data: {
      tab: Tabs.LOGIN,
    },
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      tab: Tabs.LOGIN,
    },
  },
  {
    path: 'logout',
    component: LogoutComponent,
    data: {
      tab: Tabs.LOGIN,
    },
  },
  {
    path: 'signup',
    component: SignupComponent,
    data: {
      tab: Tabs.LOGIN,
    },
  },
  {
    path: 'forms',
    children: mentorFormsRoutes,
    data: {
      tab: Tabs.MENTOR_FORMS,
    },
    canLoad: [AuthenticationGuard],
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'messages',
    loadChildren: () => import('./messages/messages.module').then((m) => m.MessagesModule),
    data: {
      tab: Tabs.MESSAGES,
    },
    canLoad: [AuthenticationGuard, ParentGuard],
    canActivate: [AuthenticationGuard, ParentGuard],
  },
  {
    path: 'messages-and-notifications',
    loadChildren: () =>
      import('./messages-and-notifications/messages-and-notifications.module').then(
        (m) => m.MessagesAndNotificationsModule,
      ),
    data: {
      tab: Tabs.MESSAGES_AND_NOTIFICATIONS,
    },
    canLoad: [AuthenticationGuard],
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'organizations',
    loadChildren: () =>
      import('./organizations/organizations.module').then((m) => m.OrganizationsModule),
    data: {
      tab: Tabs.ORGANIZATIONS,
    },
    canLoad: [AuthenticationGuard, StaffGuard],
    canActivate: [AuthenticationGuard, StaffGuard],
  },
  {
    path: 'parent-home',
    loadChildren: () => import('./parent-home/parent-home.module').then((m) => m.ParentHomeModule),
    data: {
      tab: Tabs.PARENT_HOME,
    },
    canLoad: [AuthenticationGuard, ParentGuard],
    canActivate: [AuthenticationGuard, ParentGuard],
  },
  {
    path: 'profile',
    component: ProfileComponent,
    data: {
      tab: Tabs.PROFILE,
    },
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'reports',
    loadChildren: () => import('./reports/reports.module').then((m) => m.ReportsModule),
    data: {
      tab: Tabs.REPORTS,
    },
    canLoad: [AuthenticationGuard, StaffGuard],
    canActivate: [AuthenticationGuard, StaffGuard],
  },
  {
    path: 'student-home',
    loadChildren: () =>
      import('./student-home/student-home.module').then((m) => m.StudentHomeModule),
    data: {
      tab: Tabs.STUDENT_HOME,
    },
    canLoad: [AuthenticationGuard, StudentGuard],
    canActivate: [AuthenticationGuard, StudentGuard],
  },
  {
    path: 'new-student-home',
    loadChildren: () =>
      import('./new-student-home/new-student-home.module').then((m) => m.NewStudentHomeModule),
    data: {
      tab: Tabs.NEW_STUDENT_HOME,
    },
    canLoad: [AuthenticationGuard, RedesignedStudentGuard],
    canActivate: [AuthenticationGuard, RedesignedStudentGuard],
  },
  {
    path: 'teacher-dashboards',
    loadChildren: () =>
      import('./teacher-dashboards/teacher-dashboards.module').then(
        (m) => m.TeacherDashboardsModule,
      ),
    data: {
      tab: Tabs.TEACHER_DASHBOARDS,
    },
    canLoad: [AuthenticationGuard, StaffGuard],
    canActivate: [AuthenticationGuard, StaffGuard],
  },
  {
    path: 'teacher-home',
    loadChildren: () =>
      import('./teacher-home/teacher-home.module').then((m) => m.TeacherHomeModule),
    data: {
      tab: Tabs.TEACHER_HOME,
    },
    canLoad: [AuthenticationGuard, StaffGuard],
    canActivate: [AuthenticationGuard, StaffGuard],
  },
  {
    path: 'teacher-users',
    loadChildren: () =>
      import('./teacher-users/teacher-users.module').then((m) => m.TeacherUsersModule),
    data: {
      tab: Tabs.TEACHER_USERS,
    },
    canLoad: [AuthenticationGuard, StaffGuard],
    canActivate: [AuthenticationGuard, StaffGuard],
  },
  {
    path: 'user-login',
    loadChildren: () =>
      import('./upstart-login/upstart-login.module').then((m) => m.UpstartLoginModule),
    data: {
      tab: Tabs.UPSTART_LOGIN,
    },
  },
  {
    path: 'usage',
    loadChildren: () => import('./usage/usage.module').then((m) => m.UsageModule),
    data: {
      tab: Tabs.USAGE,
    },
    canLoad: [AuthenticationGuard, ParentGuard],
    canActivate: [AuthenticationGuard, ParentGuard],
  },
  {
    path: 'notifications-manager',
    loadChildren: () =>
      import('./notifications-manager/notifications-manager.module').then(
        (m) => m.NotificationsManagerModule,
      ),
    data: {
      tab: Tabs.NOTIFICATIONS_MANAGER,
    },
    canLoad: [AuthenticationGuard, AdminGuard],
    canActivate: [AuthenticationGuard, AdminGuard],
  },
  {
    path: 'checklist',
    loadChildren: () => import('./checklist/checklist.module').then((m) => m.ChecklistModule),
    data: {
      tab: Tabs.CHECKLIST,
    },
    canLoad: [AuthenticationGuard, UpstartParentGuard],
    canActivate: [AuthenticationGuard, UpstartParentGuard],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
