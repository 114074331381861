<div mat-dialog-title>
  {{ 'Login confirmation' | translate }}
  <cl-functional-button
    (click)="dialogRef.close()"
    [icon]="Icon.cancel"
    class="confirmation-dialog__close"
    size="32px"></cl-functional-button>
</div>

<div mat-dialog-content>
  <div class="welcome-upstart-dialog__info">
    {{ 'You logged in with this email' | translate }}:
    <br />
    <span class="welcome-upstart-dialog__secondary">
      {{ emailIsHidden ? ('Email address is hidden' | translate) : data?.email }}
    </span>
    <br />
    <br />
    {{ 'and using this method' | translate }}:
    <br />
    <span class="welcome-upstart-dialog__secondary">{{ data.providerName }}</span>
    <br />
    <br />
    {{ 'This information is visible on the site next to the logout button.' | translate }}
    <br />
    <br />
    {{
      'If you haven\'t applied to a program yet, please do so by clicking "Enroll" and filling out the forms.'
        | translate
    }}
    <br />
    <br />
  </div>
</div>

<div mat-dialog-actions>
  <cl-button (click)="dialogRef.close()">
    {{ 'OK' | translate }}
  </cl-button>
</div>
