import { createAction, props } from '@ngrx/store';
import { Form, Step } from '@wap/enrollment';

export const loadSteps = createAction('[Application Initializer] Load Steps');

export const loadStepsSuccess = createAction(
  '[Enrollment API] Load Steps Success',
  props<{ steps: Step[] }>(),
);

export const loadStepsFail = createAction(
  '[Enrollment API] Load Steps Fail',
  props<{ error: string }>(),
);

export const loadForms = createAction('[Mentor Forms Component] Load Forms');

export const loadFormsSuccess = createAction(
  '[Enrollment API] Load Forms Success',
  props<{ forms: Form[] }>(),
);

export const loadFormsFail = createAction(
  '[Enrollment API] Load Forms Fail',
  props<{ error: string }>(),
);
