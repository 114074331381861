import { NgModule } from '@angular/core';
import { ContactUsDialogComponent } from './contact-us-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ButtonModule } from '@wam/component-library/button';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ContactUsDialogComponent],
  imports: [CommonModule, FormsModule, MatDialogModule, ButtonModule, TranslateModule],
  exports: [ContactUsDialogComponent],
})
export class ShowMessageDialogModule {}
