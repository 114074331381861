import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LanguageHeaderInterceptor implements HttpInterceptor {
  constructor(private translateService: TranslateService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const withLanguage = request.clone({
      setHeaders: {
        'Accept-Language': this.translateService.currentLang ?? 'en',
      },
    });
    return next.handle(withLanguage);
  }
}
