import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as authenticationActions from './state/authentication.actions';
import { State } from '@wam/shared';

@Component({
  selector: 'signup',
  template: '',
})
export class SignupComponent implements OnInit {
  constructor(private store: Store<State>) {}

  ngOnInit(): void {
    this.store.dispatch(authenticationActions.signUp());
  }
}
