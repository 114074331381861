import { createReducer, on } from '@ngrx/store';
import * as mentorFormsActions from './mentor-forms.actions';
import { EnrollmentState } from './enrollment.state';

const initialState: EnrollmentState = {
  steps: null,
  forms: null,
  events: null,
  error: null,
};

export const mentorFormsReducer = createReducer(
  initialState,
  on(mentorFormsActions.loadStepsSuccess, (state, { steps }) => ({
    ...state,
    steps,
    error: null,
  })),
  on(mentorFormsActions.loadStepsFail, (state, { error }) => ({
    ...state,
    steps: [],
    error,
  })),
  on(mentorFormsActions.loadFormsSuccess, (state, { forms }) => ({
    ...state,
    forms,
    error: null,
  })),
  on(mentorFormsActions.loadFormsFail, (state, { error }) => ({
    ...state,
    forms: [],
    error,
  })),
);

export function reducer(state, action) {
  return mentorFormsReducer(state, action);
}
