import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducer } from './state/configuration.reducer';
import { ConfigurationEffects } from './state/configuration.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('configuration', reducer),
    EffectsModule.forFeature([ConfigurationEffects]),
  ],
})
export class ConfigurationModule {}
