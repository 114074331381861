import { createReducer, on } from '@ngrx/store';
import * as configurationActions from './configuration.actions';
import { ConfigurationState, Product } from '@wam/shared';

const initialState: ConfigurationState = {
  configuration: null,
  courseConfiguration: {
    [Product.erp]: true,
    [Product.ems]: true,
    [Product.smartstart]: true,
    [Product.wacs]: true,
    [Product.curriculet]: true,
  },
};

const configurationReducer = createReducer(
  initialState,
  on(configurationActions.loadSuccess, (state, { configuration }) => ({
    ...state,
    configuration,
  })),
  on(configurationActions.loadFail, (state) => ({
    ...state,
    configuration: {},
  })),
  on(configurationActions.createOrUpdate, (state, { key, status }) => ({
    ...state,
    configuration: {
      ...state.configuration,
      ...{ [key]: status },
    },
  })),
  on(configurationActions.createSuccess, (state, { configuration }) => ({
    ...state,
    configuration: {
      ...state.configuration,
      ...configuration,
    },
  })),
  on(configurationActions.saveUserConfiguration, (state, { key, value }) => ({
    ...state,
    configuration: {
      ...state.configuration,
      ...{ [key]: value },
    },
  })),
  on(configurationActions.loadCourseConfigurationsSuccess, (state, { courseConfiguration }) => ({
    ...state,
    courseConfiguration: {
      ...state.courseConfiguration,
      ...courseConfiguration,
    },
  })),
);

export function reducer(state, action) {
  return configurationReducer(state, action);
}
