import { createReducer } from '@ngrx/store';
import { SettingsState, UserType } from '@wam/shared';
import { determineUserType, isUpstartUserLogin } from '@app/authentication/user.data';
import { getSettings } from '../settings.data';

const initialState: SettingsState = {
  settings: getSettings(),
  userType: UserType[localStorage.getItem('userType')] || determineUserType(),
  upstartUserLogin: localStorage.getItem('upstartUserLogin') === 'true' || isUpstartUserLogin(),
};

const settingsReducer = createReducer(initialState);

export function reducer(state, action) {
  return settingsReducer(state, action);
}
