import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './profile.component';
import { ProfileEffects } from './state/profile.effects';
import { EffectsModule } from '@ngrx/effects';
import { ButtonModule } from '@wam/component-library/button';
import { MatDialogModule } from '@angular/material/dialog';
import { ShowMessageDialogModule } from './contact-us-dialog/show-message-dialog.module';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { CodeInputModule } from '@kokotree-inc/angular-code-input';
import { HelpButtonModule } from '@wam/component-library/help-button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [ProfileComponent],
  imports: [
    CommonModule,
    EffectsModule.forFeature([ProfileEffects]),
    ButtonModule,
    MatDialogModule,
    ShowMessageDialogModule,
    FormsModule,
    TranslateModule,
    MatIconModule,
    CodeInputModule.forRoot({
      codeLength: 4,
      inputType: 'text',
      isCharsCode: false,
    }),
    HelpButtonModule,
    MatProgressSpinnerModule,
  ],
  exports: [ProfileComponent],
})
export class ProfileModule {}
