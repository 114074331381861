import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, take, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';

import { configurationSelectors, State, User, UserType } from '@wam/shared';
import { authenticationSelectors } from '@wam/authentication';
import * as fromLanding from '@app/landing/state/landing.selectors';
import { isNil } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class RedesignedStudentGuard {
  constructor(
    private store: Store<State>,
    private router: Router,
  ) {}

  canActivate(): Observable<boolean> {
    return combineLatest([
      this.store.pipe(
        select(authenticationSelectors.getCurrentUser),
        map((currentUser: User) => this.isStudent(currentUser)),
      ),
      this.isClaimSelected(),
      this.store.pipe(select(configurationSelectors.isEnabled('STUDENT_APP_REDESIGN'))),
    ]).pipe(
      tap(([isStudent, claimSelected, isStudentAppRedesignEnabled]) => {
        if (!isStudent && !claimSelected) {
          this.router.navigate(['/']);
          return;
        }
        if (isStudent && !isStudentAppRedesignEnabled) {
          this.router.navigate(['/student-home']);
        }
      }),
      map(
        ([isStudent, claimSelected, isStudentAppRedesignEnabled]) =>
          (isStudent && isStudentAppRedesignEnabled) || (!isStudent && claimSelected),
      ),
      take(1),
    );
  }

  canLoad(): Observable<boolean> {
    return this.canActivate();
  }

  private isStudent(user: User): boolean {
    return user.type === UserType.STUDENT;
  }

  private isClaimSelected(): Observable<boolean> {
    return this.store
      .pipe(select(fromLanding.getSelectedClaim))
      .pipe(map((claim) => !isNil(claim)));
  }
}
