import { Injectable } from '@angular/core';
import { settingsSelectors, State } from '@wam/shared';
import { ActionsSubject, select, Store } from '@ngrx/store';

import * as configurationActions from './state/configuration.actions';
import { isNil } from 'lodash-es';
import { map, mergeMap, skipWhile, take, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ofType } from '@ngrx/effects';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationInitializerService {
  constructor(
    private store: Store<State>,
    private actionListener: ActionsSubject,
  ) {}

  initialize(): Observable<void> {
    return this.store.pipe(
      select(settingsSelectors.getSettings),
      skipWhile(isNil),
      tap(() => this.store.dispatch(configurationActions.load())),
      mergeMap(() =>
        this.actionListener.pipe(
          ofType(configurationActions.loadSuccess, configurationActions.loadFail),
        ),
      ),
      take(1),
      map(() => void 0),
    );
  }
}
