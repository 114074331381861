import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, take, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';

import { settingsSelectors, State, UserRole, UserType } from '@wam/shared';
import { authenticationSelectors } from '@wam/authentication';

@Injectable({
  providedIn: 'root',
})
export class InternalAdminGuard {
  constructor(
    private store: Store<State>,
    private router: Router,
  ) {}

  canActivate(): Observable<boolean> {
    return combineLatest([
      this.store.pipe(select(settingsSelectors.getUserType)),
      this.store.pipe(select(authenticationSelectors.getUserRoles)),
    ]).pipe(
      map(
        ([userType, userRoles]) =>
          userType === UserType.ADMIN && userRoles?.includes(UserRole.INTERNAL_ADMIN),
      ),
      tap((isInternalAdmin: boolean) => {
        if (!isInternalAdmin) {
          this.router.navigate(['/']);
        }
      }),
      take(1),
    );
  }

  canLoad(): Observable<boolean> {
    return this.canActivate();
  }
}
