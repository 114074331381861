import { Routes } from '@angular/router';
import { MentorFormsComponent } from './mentor-forms.component';

export const mentorFormsRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: MentorFormsComponent,
  },
];
