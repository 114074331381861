import { Component, HostBinding, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Icon } from '@wam/icons';
import { User } from '@wam/shared';

@Component({
  templateUrl: './welcome-upstart-dialog.component.html',
  styleUrls: ['./welcome-upstart-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WelcomeUpstartDialogComponent {
  @HostBinding('class') mainClass = 'welcome-upstart-dialog';

  Icon = Icon;

  private readonly HIDDEN_EMAIL_DOMAIN_NAME = '@privaterelay';

  get emailIsHidden(): boolean {
    return !this.data.email || this.data.email.includes(this.HIDDEN_EMAIL_DOMAIN_NAME);
  }

  constructor(
    public dialogRef: MatDialogRef<WelcomeUpstartDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: User,
  ) {}
}
