import { ActionReducerMap } from '@ngrx/store';

import { reducer as authenticationReducer } from '@app/authentication/state/authentication.reducer';
import { reducer as settingsReducer } from '@app/settings/state/settings.reducer';
import { reducer as filteringReducer } from '@app/filtering/state/filtering.reducer';
import { reducer as feedReducer } from '@app/feed/state/feed.reducer';
import { reducer as claimsReducer } from '@app/claims/state/claims.reducer';
import { reducer as configurationReducer } from '@app/configuration/state/configuration.reducer';
import { reducer as goalsReducer } from '@app/goals/state/goals.reducer';
import { reducer as messagesAndNotificationsReducer } from '@app/messages-and-notifications/state/messages-and-notifications.reducer';
import { reducer as configurationManagementReducer } from '@app/configuration-management/state/configuration.reducer';

import { State } from '@wam/shared';
import { landingReducer } from '@app/landing/state/landing.reducer';

export const reducers: ActionReducerMap<State> = {
  authentication: authenticationReducer,
  settings: settingsReducer,
  filtering: filteringReducer,
  feed: feedReducer,
  claims: claimsReducer,
  landing: landingReducer,
  configuration: configurationReducer,
  goals: goalsReducer,
  messagesAndNotifications: messagesAndNotificationsReducer,
  configurationManagement: configurationManagementReducer,
};
