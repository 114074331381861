import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { EffectsModule } from '@ngrx/effects';
import { AuthenticationEffects } from './state/authentication.effects';
import { LoginComponent } from './login.component';
import { LogoutComponent } from './logout.component';
import { LoggedInComponent } from './logged-in.component';
import { StoreModule } from '@ngrx/store';
import { reducer } from './state/authentication.reducer';
import { SignupComponent } from './signup.component';
import { LandingEffects } from '@app/landing/state/landing.effects';

@NgModule({
  declarations: [LoggedInComponent, LoginComponent, LogoutComponent, SignupComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    StoreModule.forFeature('authentication', reducer),
    EffectsModule.forFeature([AuthenticationEffects, LandingEffects]),
  ],
  providers: [{ provide: 'window', useFactory: () => window }],
})
export class AuthenticationModule {}
