import { createReducer, on } from '@ngrx/store';
import { AuthenticationState, AuthenticationType } from '@wam/shared';
import { determineAuthenticationType } from '../authentication.data';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import * as authenticationActions from './authentication.actions';

const initialState: AuthenticationState & { clientId: string; orgCode?: string } = {
  user: null,
  pending: false,
  authenticationType:
    AuthenticationType[localStorage.getItem('authenticationType')] ?? determineAuthenticationType(),
  readOnly: coerceBooleanProperty(localStorage.getItem('readOnly') ?? true),
  clientId: localStorage.getItem('clientId'),
  logoutUrl: localStorage.getItem('logoutUrl') ?? undefined,
  includeLogoutToken:
    coerceBooleanProperty(localStorage.getItem('includeLogoutToken')) || undefined,
  rosteringProvider: null,
};

const authenticationReducer = createReducer(
  initialState,
  on(authenticationActions.refreshToken, authenticationActions.authorize, (state) => ({
    ...state,
    pending: true,
  })),
  on(authenticationActions.updateCurrentUser, (state, { user }) => ({
    ...state,
    user,
    pending: false,
  })),
  on(authenticationActions.getReadOnlySuccess, (state, { readOnly }) => ({
    ...state,
    readOnly,
  })),
  on(authenticationActions.updateClientId, (state, { clientId }) => ({
    ...state,
    clientId,
  })),
  on(authenticationActions.authorizeWithTokens, (state, { logoutUrl, includeLogoutToken }) => ({
    ...state,
    logoutUrl,
    includeLogoutToken,
  })),
  on(authenticationActions.getOrgCodeSuccess, (state, { orgCode }) => ({
    ...state,
    orgCode,
  })),
  on(authenticationActions.determineRosteringProviderSuccess, (state, { rosteringProvider }) => ({
    ...state,
    rosteringProvider,
  })),
);

export function reducer(state, action) {
  return authenticationReducer(state, action);
}
