import { NgModule } from '@angular/core';
import { LogoutConfirmDialogComponent } from './logout-confirm-dialog.component';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { LogoutConfirmDialogService } from './logout-confirm-dialog.service';
import { ButtonModule } from '@wam/component-library/button';

@NgModule({
  declarations: [LogoutConfirmDialogComponent],
  imports: [CommonModule, TranslateModule, MatDialogModule, ButtonModule],
  providers: [LogoutConfirmDialogService],
  exports: [LogoutConfirmDialogComponent],
})
export class LogoutConfirmDialogModule {}
