import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, take, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { isUpstartParent } from './user.data';

import { settingsSelectors, State, UserType } from '@wam/shared';

@Injectable({
  providedIn: 'root',
})
export class UpstartParentGuard {
  constructor(
    private store: Store<State>,
    private router: Router,
  ) {}

  canActivate(): Observable<boolean> {
    return this.store.pipe(
      select(settingsSelectors.getUserType),
      map((userType: UserType) => isUpstartParent(userType)),
      tap((isUpstartParent: boolean) => {
        if (!isUpstartParent) {
          this.router.navigate(['/']);
        }
      }),
      take(1),
    );
  }

  canLoad(): Observable<boolean> {
    return this.canActivate();
  }
}
