import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as mentorFormsActions from './state/mentor-forms.actions';
import { distinctUntilChanged, map, takeUntil, tap } from 'rxjs/operators';
import { Form, FormStatus } from '@wap/enrollment';
import { interval, Observable, Subject } from 'rxjs';
import { isEqual } from 'lodash-es';
import { State } from '@wam/shared';
import { enrollmentSelectors } from './state/enrollment.selectors';

@Component({
  selector: 'mentor-forms',
  templateUrl: './mentor-forms.component.html',
  styleUrls: ['./mentor-forms.component.scss'],
})
export class MentorFormsComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<void>();
  private disabledForms: string[] = [];
  forms$: Observable<Form[] | null> = this.store.pipe(
    select(enrollmentSelectors.getForms),
    distinctUntilChanged(isEqual),
    tap(() => (this.disabledForms = [])),
    tap(() => this.store.dispatch(mentorFormsActions.loadSteps())),
  );
  toDoForms$ = this.forms$.pipe(
    map((forms: Form[]) => forms?.filter((form) => form.status === FormStatus.TODO)),
  );
  optionalForms$ = this.forms$.pipe(
    map((forms: Form[]) => forms?.filter((form) => form.status === FormStatus.OPTIONAL)),
  );
  completedForms$ = this.forms$.pipe(
    map((forms: Form[]) => forms?.filter((form) => form.status === FormStatus.COMPLETED)),
  );
  private poll$ = interval(3000).pipe(
    tap(() => this.store.dispatch(mentorFormsActions.loadForms())),
    takeUntil(this.destroyed$),
  );

  constructor(private store: Store<State>) {}

  isDisabled(id: string): boolean {
    return this.disabledForms.includes(id);
  }

  disableForm(id: string): void {
    this.disabledForms = [...this.disabledForms, id];
  }

  ngOnInit(): void {
    this.store.dispatch(mentorFormsActions.loadForms());
    this.poll$.subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
