import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';
import { State, UserType } from '@wam/shared';
import { authenticationSelectors } from '@wam/authentication';
import { firstValueFrom } from 'rxjs';
import { newTracker } from '@snowplow/browser-tracker';
import { SettingsService } from '@app/settings/settings.service';

@Injectable({
  providedIn: 'root',
})
export class SnowplowInitializerService {
  constructor(
    private store: Store<State>,
    private settingsService: SettingsService,
  ) {}

  initialize(): Promise<boolean> {
    return firstValueFrom(
      this.store.pipe(
        select(authenticationSelectors.getCurrentUser),
        map((user) => {
          if (user?.type === UserType.UPSTART) {
            const tracker = newTracker(
              'main-tracker',
              this.settingsService.settings.snowplow.collectorUrl,
              {
                appId: 'waterford-upstart',
                respectDoNotTrack: true,
                anonymousTracking: false,
                contexts: {
                  session: true,
                },
                plugins: [],
                cookieSameSite: 'Lax',
              },
            );

            tracker?.setUserId(user.cognitoId);
          }

          return true;
        }),
        take(1),
      ),
    );
  }
}
