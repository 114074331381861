import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as messagesAndNotificationsActions from './messages-and-notifications.actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { MessagesAndNotificationsService } from '../messages-and-notifications.service';

@Injectable()
export class MessagesAndNotificationsEffects {
  loadNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(messagesAndNotificationsActions.loadNotifications),
      mergeMap(() =>
        this.messagesAndNotificationService.loadNotifications().pipe(
          map((notifications) =>
            messagesAndNotificationsActions.loadNotificationsSuccess({ notifications }),
          ),
          catchError((error) =>
            of(messagesAndNotificationsActions.loadNotificationsFail({ error })),
          ),
        ),
      ),
    ),
  );

  markNotificationAsRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(messagesAndNotificationsActions.markNotificationAsRead),
      mergeMap(({ notification }) =>
        this.messagesAndNotificationService.markAsRead(notification).pipe(
          map(() =>
            messagesAndNotificationsActions.markNotificationAsReadSuccess({ notification }),
          ),
          catchError(() =>
            of(messagesAndNotificationsActions.markNotificationAsReadFailed({ notification })),
          ),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private messagesAndNotificationService: MessagesAndNotificationsService,
  ) {}
}
