import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { HelpWidgetComponent } from './help-widget.component';
import { LiveChatWidgetModule } from '@livechat/widget-angular';

@NgModule({
  declarations: [HelpWidgetComponent],
  imports: [CommonModule, TranslateModule, LiveChatWidgetModule],
  exports: [HelpWidgetComponent],
  providers: [{ provide: 'window', useFactory: () => window }],
})
export class HelpWidgetModule {}
