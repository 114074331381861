<div class="mentor-forms">
  <div class="mentor-forms__title">{{ 'Forms' | translate }}</div>

  <div class="mentor-forms__content">
    <div
      *ngIf="(toDoForms$ | async)?.length > 0"
      class="mentor-forms__section">
      <header class="mentor-forms__header mentor-forms__header--red">
        {{ 'To do:' | translate }}
        <div class="mentor-forms__header__count">{{ (toDoForms$ | async).length }}</div>
      </header>

      <details
        *ngFor="let form of toDoForms$ | async"
        class="mentor-forms__form-details">
        <summary class="mentor-forms__form-details__summary">{{ form.name }}</summary>

        <div class="mentor-forms__form-details__content">
          <div class="mentor-forms__form-details__description">{{ form.description }}</div>
        </div>
      </details>
    </div>

    <div
      *ngIf="(optionalForms$ | async)?.length > 0"
      class="mentor-forms__section">
      <header class="mentor-forms__header mentor-forms__header--gray">
        {{ 'Optional' | translate }}:
      </header>

      <details
        *ngFor="let form of optionalForms$ | async"
        class="mentor-forms__form-details">
        <summary class="mentor-forms__form-details__summary">{{ form.name }}</summary>

        <div class="mentor-forms__form-details__content">
          <div class="mentor-forms__form-details__description">{{ form.description }}</div>
          <a
            (click)="disableForm(form.formId)"
            [class.mentor-forms__form-details__open--disabled]="isDisabled(form.formId)"
            [href]="form.formUrl"
            class="mentor-forms__form-details__open"
            target="_blank">
            {{ 'Open' | translate }}
          </a>
        </div>
      </details>
    </div>

    <div
      *ngIf="(completedForms$ | async)?.length > 0"
      class="mentor-forms__section">
      <header class="mentor-forms__header">{{ 'Completed:' | translate }}</header>

      <div *ngFor="let form of completedForms$ | async">
        <div class="mentor-forms__form-details">
          <div class="mentor-forms__form-details__title">
            {{ form.name }}
          </div>
          <div class="mentor-forms__form-details__description">
            {{ form.description }}
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="(forms$ | async)?.length === 0">
      <h3>{{ 'You currently have no forms assigned' | translate }}</h3>
    </ng-container>
  </div>
</div>
