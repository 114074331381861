import { Injectable } from '@angular/core';
import { Form, Step } from '@wap/enrollment';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { ApiGatewayService, authenticationSelectors } from '@wam/authentication';
import { map, mergeMap, take } from 'rxjs/operators';
import { State, User } from '@wam/shared';

@Injectable({
  providedIn: 'root',
})
export class EnrollmentService {
  constructor(
    private store: Store<State>,
    private apiGatewayService: ApiGatewayService,
  ) {}

  getSteps(): Observable<Step[]> {
    return this.store.pipe(
      select(authenticationSelectors.getCurrentUser),
      map((user: User) => user.application),
      mergeMap((application: string) =>
        this.apiGatewayService.get<Step[]>(
          `enrollment/v1/apps/${application}/orgs/upstart/process/upstart/users/steps`,
        ),
      ),
      take(1),
    );
  }

  getForms(): Observable<Form[]> {
    return this.store.pipe(
      select(authenticationSelectors.getCurrentUser),
      map((user: User) => user.application),
      mergeMap((application: string) =>
        this.apiGatewayService.get<Form[]>(
          `enrollment/v1/apps/${application}/orgs/upstart/process/upstart/users/steps/forms`,
        ),
      ),
      take(1),
    );
  }
}
