import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiGatewayService, authenticationSelectors } from '@wam/authentication';
import { INotification, State, User } from '@wam/shared';
import { select, Store } from '@ngrx/store';
import { map, mergeMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MessagesAndNotificationsService {
  constructor(
    private apiGatewayService: ApiGatewayService,
    private store: Store<State>,
  ) {}

  loadNotifications(): Observable<INotification[]> {
    return this.withUser().pipe(
      mergeMap(({ application, uuid, cognitoId }) =>
        this.apiGatewayService.simpleGet<{ results: INotification[] }>(
          `feed/v4/apps/${application}/groups/notification/feeds/${
            uuid ?? cognitoId
          }/activities?limit=100`,
        ),
      ),
      map(({ results }) => results),
      take(1),
    );
  }

  markAsRead(notification: INotification): Observable<INotification> {
    return this.withUser().pipe(
      mergeMap(({ application, uuid, cognitoId }) =>
        this.apiGatewayService.simplePatch<INotification>(
          `feed/v4/apps/${application}/groups/notification/feeds/${uuid ?? cognitoId}/activities/${
            notification.id
          }`,
          { read: true },
        ),
      ),
      take(1),
    );
  }

  private withUser(): Observable<User> {
    return this.store.pipe(select(authenticationSelectors.getCurrentUser));
  }
}
