import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '@wam/component-library/button';
import { IconModule } from '@wam/icons';
import { WelcomeUpstartDialogComponent } from './welcome-upstart-dialog.component';
import { WelcomeUpstartDialogService } from './welcome-upstart-dialog.service';
import { FunctionalButtonModule } from '@wam/component-library/functional-button';

@NgModule({
  declarations: [WelcomeUpstartDialogComponent],
  imports: [
    CommonModule,
    TranslateModule,
    IconModule,
    MatDialogModule,
    ButtonModule,
    FunctionalButtonModule,
  ],
  providers: [WelcomeUpstartDialogService],
  exports: [WelcomeUpstartDialogComponent],
})
export class WelcomeUpstartDialogModule {}
