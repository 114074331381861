import { Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { AuthenticationType, State } from '@wam/shared';
import * as authenticationActions from './state/authentication.actions';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'logged-in',
  template: '',
})
export class LoggedInComponent implements OnInit {
  constructor(
    private store: Store<State>,
    @Inject('window') private window: Window,
  ) {}

  ngOnInit(): void {
    const queryParams = new URLSearchParams(this.window.location.search);

    const code = queryParams.get('code');
    const idToken = queryParams.get('idToken');
    const refreshToken = queryParams.get('refreshToken');
    const clientId = queryParams.get('clientId');
    const logoutUrl = queryParams.get('logoutRedirectUrl') ?? undefined;
    const includeLogoutToken =
      coerceBooleanProperty(queryParams.get('includeLogoutToken')) || undefined;

    const skipHome = coerceBooleanProperty(queryParams.get('skipHome')) || undefined;
    if (skipHome !== undefined) {
      localStorage.setItem('skipHome', `${skipHome}`);
    }

    const documentId = queryParams.get('documentId') || undefined;
    if (documentId !== undefined) {
      localStorage.setItem('documentId', `${documentId}`);
    }

    if (code) {
      this.store.dispatch(authenticationActions.authorize({ code }));
    }
    if (idToken && clientId) {
      this.store.dispatch(
        authenticationActions.authorizeWithTokens({
          idToken,
          refreshToken,
          logoutUrl,
          includeLogoutToken,
        }),
      );
      this.store.dispatch(authenticationActions.updateClientId({ clientId }));
      this.store.dispatch(
        authenticationActions.updateAuthenticationType({
          authenticationType: AuthenticationType.FUSION,
        }),
      );
    } else if (idToken) {
      this.store.dispatch(
        authenticationActions.authorizeWithTokens({
          idToken,
          refreshToken,
          logoutUrl,
          includeLogoutToken,
        }),
      );
      this.store.dispatch(
        authenticationActions.updateAuthenticationType({
          authenticationType: AuthenticationType.COGNITO,
        }),
      );
    }
  }
}
