import { AuthenticationType, UserType } from '@wam/shared';
import { determineUserType } from './user.data';

export function determineAuthenticationType(url = window.location.href): AuthenticationType {
  let type: AuthenticationType;
  const userType: UserType = determineUserType(url);
  switch (userType) {
    case UserType.STUDENT:
    case UserType.TEACHER:
    case UserType.ADMIN:
      type = AuthenticationType.FUSION;
      break;
    default:
      type = AuthenticationType.COGNITO;
      break;
  }
  localStorage.setItem('authenticationType', type);
  return type;
}
