import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, take, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';

import { settingsSelectors, State } from '@wam/shared';
import { authenticationSelectors } from '@wam/authentication';
import { isInternalAdminOrAccountAdminOrSupportEngineer } from '../authentication/user.data';

@Injectable({
  providedIn: 'root',
})
export class InternalAdminOrAccountAdminOrSupportEngineerGuard {
  constructor(
    private store: Store<State>,
    private router: Router,
  ) {}

  canActivate(): Observable<boolean> {
    return combineLatest([
      this.store.pipe(select(settingsSelectors.getUserType)),
      this.store.pipe(select(authenticationSelectors.getUserRoles)),
    ]).pipe(
      map(([userType, userRoles]) =>
        isInternalAdminOrAccountAdminOrSupportEngineer(userType, userRoles),
      ),
      tap((isInternalAdminOrAccountAdmin: boolean) => {
        if (!isInternalAdminOrAccountAdmin) {
          this.router.navigate(['/']);
        }
      }),
      take(1),
    );
  }

  canLoad(): Observable<boolean> {
    return this.canActivate();
  }
}
