import { NgModule } from '@angular/core';
import { MentorFormsComponent } from './mentor-forms.component';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { reducer } from './state/mentor-forms.reducer';
import { EffectsModule } from '@ngrx/effects';
import { MentorFormsEffects } from './state/mentor-forms.effects';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [MentorFormsComponent],
  imports: [
    CommonModule,
    StoreModule.forFeature('enrollment', reducer),
    EffectsModule.forFeature([MentorFormsEffects]),
    TranslateModule,
  ],
  exports: [MentorFormsComponent],
})
export class MentorFormsModule {}
