import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { Router } from '@angular/router';
import { combineLatest, from, of } from 'rxjs';

import { settingsSelectors, State, User, UserRole } from '@wam/shared';
import { AuthenticationService } from '../authentication.service';
import { ConfigurationInitializerService } from '@app/configuration/configuration-initializer.service';
import { select, Store } from '@ngrx/store';
import { authenticationSelectors } from '@wam/authentication';
import * as authenticationActions from './authentication.actions';
import { isNil } from 'lodash-es';
import * as profileActions from '../../profile/state/profile.actions';
import { MessagesAndNotificationsInitializerService } from '@app/messages-and-notifications/messages-and-notifications-initializer.service';
import { BatchService } from '@app/organizations/batch.service';
import { INITIAL_PROGRAMS_DATA_LOCAL_STORAGE_KEY } from '@app/constants';
import { ThemingInitializerService } from '@app/theming-initializer.service';
import { LandingService } from '@app/landing/landing.service';
import { EulaService } from '@app/eula/eula.service';
import { WelcomeUpstartDialogService } from '@app/shared/welcome-upstart-dialog/welcome-upstart-dialog.service';
import { SnowplowInitializerService } from '@app/snowplow-initializer.service';
import { OrgUpdatesLockingInitializerService } from '@app/shared/org-updates-locking-initializer.service';

@Injectable()
export class AuthenticationEffects {
  logIn$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authenticationActions.logIn),
        mergeMap(() => this.authenticationService.logIn()),
      ),
    { dispatch: false },
  );

  signUp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authenticationActions.signUp),
        mergeMap(() => this.authenticationService.signUp()),
      ),
    { dispatch: false },
  );

  authorize$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authenticationActions.authorize),
      mergeMap(({ code }) =>
        this.authenticationService.authorize(code).pipe(
          tap((user: User) => localStorage.setItem('currentUser', JSON.stringify(user))),
          mergeMap((user: User) => [
            authenticationActions.updateCurrentUser({ user }),
            authenticationActions.getReadOnly(),
          ]),
        ),
      ),
    ),
  );

  getReadOnly$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authenticationActions.getReadOnly),
      mergeMap(() => this.store.pipe(select(authenticationSelectors.getCurrentUser)).pipe(take(1))),
      mergeMap(({ organization, type }) =>
        this.authenticationService.getReadOnly(organization, type),
      ),
      map((readOnly) => authenticationActions.getReadOnlySuccess({ readOnly })),
    ),
  );

  getReadOnlySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authenticationActions.getReadOnlySuccess),
        tap(({ readOnly }) => localStorage.setItem('readOnly', `${readOnly}`)),
      ),
    { dispatch: false },
  );

  authorizeWithTokens$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authenticationActions.authorizeWithTokens),
      tap(({ logoutUrl, includeLogoutToken }) => {
        if (!isNil(logoutUrl)) {
          localStorage.setItem('logoutUrl', logoutUrl);
        }
        if (!isNil(includeLogoutToken)) {
          localStorage.setItem('includeLogoutToken', `${includeLogoutToken}`);
        }
      }),
      withLatestFrom(this.store.pipe(select(settingsSelectors.getUserType)).pipe(take(1))),
      map(([{ idToken, refreshToken }, userType]) =>
        this.authenticationService.buildUser(idToken, refreshToken, userType),
      ),
      tap((user: User) => localStorage.setItem('currentUser', JSON.stringify(user))),
      mergeMap((user: User) => [
        authenticationActions.updateCurrentUser({ user }),
        authenticationActions.getReadOnly(),
      ]),
    ),
  );

  updateCurrentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authenticationActions.updateCurrentUser),
      tap(({ user: { roles } }) => {
        if (roles?.includes(UserRole.DISTRICT_ADMIN)) {
          this.store.dispatch(authenticationActions.determineRosteringProvider());
        }
      }),
      mergeMap((refreshed) =>
        this.configurationInitializerService.initialize().pipe(map(() => refreshed)),
      ),
      mergeMap(({ refreshed }) =>
        from(this.themingInitializerService.initialize()).pipe(map(() => refreshed)),
      ),
      mergeMap((refreshed) =>
        this.messagesAndNotificationsInitializerService.initialize().pipe(
          mergeMap(() => this.goalsInitializerService.initialize()),
          map(() => refreshed),
        ),
      ),
      tap((refreshed) => {
        if (!refreshed) {
          if (!isNil(localStorage.getItem(INITIAL_PROGRAMS_DATA_LOCAL_STORAGE_KEY))) {
            const { language } = JSON.parse(
              localStorage.getItem(INITIAL_PROGRAMS_DATA_LOCAL_STORAGE_KEY),
            );
            this.store.dispatch(profileActions.updateLanguage({ language }));
          }
        }
      }),
      tap(() => {
        this.snowplowInitializerService.initialize();
      }),
      mergeMap((refreshed) => {
        return combineLatest([
          this.landingService.mustShowLanding(),
          this.eulaService.mustShowEula(),
        ]).pipe(
          tap(([mustShowLanding, mustShowEula]) => {
            if (mustShowEula) {
              this.router.navigateByUrl('/eula');
            } else if (mustShowLanding) {
              this.router.navigateByUrl('/landing');
            } else if (!refreshed) {
              this.router.navigateByUrl('/');
            }
          }),
        );
      }),
      map(() => authenticationActions.logInSuccess()),
    ),
  );

  logInSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authenticationActions.logInSuccess),
        tap(() => this.welcomeDialog.initWelcomeDialog()),
      ),
    { dispatch: false },
  );

  updateClientId$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authenticationActions.updateClientId),
        tap(({ clientId }) => localStorage.setItem('clientId', clientId)),
      ),
    { dispatch: false },
  );

  refreshToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authenticationActions.refreshToken),
      mergeMap(() =>
        this.authenticationService.refreshToken().pipe(
          tap((user: User) => localStorage.setItem('currentUser', JSON.stringify(user))),
          map((user: User) => authenticationActions.updateCurrentUser({ user, refreshed: true })),
          catchError(() =>
            of(authenticationActions.refreshTokenFailed(), authenticationActions.logOut()),
          ),
        ),
      ),
    ),
  );

  logOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authenticationActions.logOut),
        mergeMap(() => this.router.navigateByUrl('/logout')),
      ),
    { dispatch: false },
  );

  performLogoutSteps$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authenticationActions.performLogoutSteps),
      tap(() => {
        localStorage.clear();
      }),
      mergeMap(() => this.authenticationService.logOut()),
      mergeMap(() => [authenticationActions.clearNgRxStore()]),
    ),
  );

  getOrgCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authenticationActions.getOrgCode),
      mergeMap(() =>
        this.authenticationService
          .getOrgCode()
          .pipe(map((orgCode) => authenticationActions.getOrgCodeSuccess({ orgCode }))),
      ),
      catchError(() => of(authenticationActions.getOrgCodeFailure())),
    ),
  );

  determineRosteringProvider$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authenticationActions.determineRosteringProvider),
      mergeMap(() =>
        this.batchService.getRosteringProvider().pipe(
          map((rosteringProvider) =>
            authenticationActions.determineRosteringProviderSuccess({ rosteringProvider }),
          ),
          catchError((error) =>
            of(authenticationActions.determineRosteringProviderFailed({ error })),
          ),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private authenticationService: AuthenticationService,
    private router: Router,
    private configurationInitializerService: ConfigurationInitializerService,
    private messagesAndNotificationsInitializerService: MessagesAndNotificationsInitializerService,
    private goalsInitializerService: OrgUpdatesLockingInitializerService,
    private themingInitializerService: ThemingInitializerService,
    private store: Store<State>,
    private batchService: BatchService,
    private landingService: LandingService,
    private eulaService: EulaService,
    private welcomeDialog: WelcomeUpstartDialogService,
    private snowplowInitializerService: SnowplowInitializerService,
  ) {}
}
