import { createReducer, on } from '@ngrx/store';
import * as messagesAndNotificationsActions from './messages-and-notifications.actions';
import { INotification, MessagesAndNotificationsState } from '@wam/shared';

const initialState: MessagesAndNotificationsState = {
  notifications: [],
  error: null,
  loading: true,
};

export const messagesAndNotificationsReducer = createReducer(
  initialState,
  on(messagesAndNotificationsActions.loadNotifications, (state) => ({
    ...state,
  })),
  on(messagesAndNotificationsActions.loadNotificationsSuccess, (state, { notifications }) => ({
    ...state,
    notifications,
    loading: false,
  })),
  on(messagesAndNotificationsActions.loadNotificationsFail, (state, { error }) => ({
    ...state,
    notifications: [],
    error,
    loading: false,
  })),
  on(messagesAndNotificationsActions.markNotificationAsRead, (state, { notification }) => ({
    ...state,
    notifications: markAsRead(state.notifications, notification),
  })),
  on(messagesAndNotificationsActions.markNotificationAsReadFailed, (state, { notification }) => ({
    ...state,
    notifications: markAsUnread(state.notifications, notification),
  })),
);

export function markAsRead(
  notifications: INotification[],
  toMark: INotification,
  read: boolean = true,
) {
  return notifications.map((notification) => {
    if (notification.id === toMark.id) {
      return {
        ...notification,
        isRead: read,
      };
    }
    return notification;
  });
}

export function markAsUnread(notifications: INotification[], toMark: INotification) {
  return markAsRead(notifications, toMark, false);
}

export function reducer(state, action) {
  return messagesAndNotificationsReducer(state, action);
}
