import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EulaService } from '../../eula/eula.service';
import { Store } from '@ngrx/store';
import { authenticationSelectors } from '@wam/authentication';
import { Icon } from '@wam/icons';
import { State, User, UserType, configurationSelectors } from '@wam/shared';
import { combineLatest, filter, Observable, switchMap, take, tap } from 'rxjs';
import { WelcomeUpstartDialogComponent } from './welcome-upstart-dialog.component';
import { saveUserConfiguration } from '@app/configuration/state/configuration.actions';

@Injectable()
export class WelcomeUpstartDialogService {
  Icon = Icon;

  constructor(
    private store: Store<State>,
    private dialog: MatDialog,
    private eula: EulaService,
  ) {}

  initWelcomeDialog(): void {
    combineLatest([
      this.store.select(authenticationSelectors.getCurrentUser),
      this.eula.isEulaAccepted(),
      this.isShown(),
    ])
      .pipe(
        filter(
          ([user, isEulaAccepted, isShown]) => isEulaAccepted && !isShown && this.isRightUser(user),
        ),
        take(1),
        switchMap(([user]) => this.showWelcomeDialog(user).pipe(tap(() => this.markAsShown()))),
      )
      .subscribe();
  }

  private isRightUser(user?: User): boolean {
    return user?.type === UserType.UPSTART;
  }

  private showWelcomeDialog(user: User): Observable<void> {
    return this.dialog
      .open(WelcomeUpstartDialogComponent, {
        data: user,
        panelClass: 'no-padding-dialog',
      })
      .afterClosed();
  }

  private markAsShown(): void {
    this.store.dispatch(saveUserConfiguration({ key: 'welcomeDialog', value: true }));
  }

  private isShown(): Observable<boolean> {
    return this.store.select(configurationSelectors.getUserConfiguration<boolean>('welcomeDialog'));
  }
}
