import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import * as configurationActions from './configuration.actions';
import { Observable, forkJoin, of } from 'rxjs';
import { ConfigurationDataService } from '../configuration-data.service';
import { IConfiguration } from '@wap/configuration';
import { Store } from '@ngrx/store';
import { User } from '@wam/shared';
import { authenticationSelectors } from '@wam/authentication';
import { EnrollmentType } from '@wap/enrollment-v2';
import { UpstartAccountType } from '@app/programs/enrollment-dialogs/enrollment-wizard-dialog.service';
import { isNil } from 'lodash-es';

@Injectable()
export class ConfigurationEffects {
  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(configurationActions.load),
      withLatestFrom(this.store.select(authenticationSelectors.getCurrentUser)),
      switchMap(([_, user]) =>
        this.configurationService.load().pipe(
          switchMap((configuration: IConfiguration) => this.migrate(configuration, user)),
          switchMap((configuration: IConfiguration) => {
            const courseConfiguration = this.safeParseJson(
              configuration.COURSE_CONFIG as string,
              {},
            );

            if (isNil(configuration.language)) {
              configuration.language = 'en';
            }

            if (isNil(configuration.tabPosition)) {
              configuration.tabPosition = 'top';
            }

            return [
              configurationActions.loadSuccess({ configuration }),
              configurationActions.loadCourseConfigurationsSuccess({ courseConfiguration }),
            ];
          }),
          catchError((error) => [configurationActions.loadFail(error?.toString())]),
        ),
      ),
    ),
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(configurationActions.createOrUpdate),
      mergeMap((configuration) =>
        this.configurationService
          .save(
            configuration.key,
            configuration.status,
            configuration.organization,
            configuration.school,
          )
          .pipe(
            map((response: { data: { attributes: IConfiguration } }) =>
              configurationActions.createSuccess({ configuration: response.data.attributes }),
            ),
            catchError((error) => of(configurationActions.createFail(error?.toString()))),
          ),
      ),
    ),
  );

  saveUserPrivateConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(configurationActions.saveUserConfiguration),
      mergeMap(({ key, value }) =>
        this.configurationService.savePrivate(key, value).pipe(
          map((response: { data: { attributes: IConfiguration } }) =>
            configurationActions.saveUserConfigurationSuccess({
              configuration: response.data.attributes,
            }),
          ),
          catchError((error) =>
            of(configurationActions.saveUserConfigurationFail(error?.toString())),
          ),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private configurationService: ConfigurationDataService,
    private store: Store,
  ) {}

  private safeParseJson<T>(json: string, defaultValue: Partial<T>): T {
    try {
      return JSON.parse(json) || (defaultValue as T);
    } catch (e) {
      return defaultValue as T;
    }
  }

  private mapWelcomeDialog(user: User): boolean {
    const emails = this.safeParseJson(localStorage.getItem('welcomeDialog'), []) as string[];
    return emails.includes(user.email);
  }

  private mapUpstartAccountType(): UpstartAccountType {
    let upstartAccountType;
    // map old value
    switch (localStorage.getItem('enrollmentType')) {
      case EnrollmentType.FAMILY:
        upstartAccountType = UpstartAccountType.FAMILY;
        break;
      case EnrollmentType.CHILD_CARE:
        upstartAccountType = UpstartAccountType.CARE_CENTER;
        break;
      default:
        upstartAccountType = undefined;
    }
    localStorage.removeItem('enrollmentType');

    return upstartAccountType;
  }

  private migrate(configuration: IConfiguration, user: User): Observable<IConfiguration> {
    const userConfig = {
      tabPosition: localStorage.getItem('tabPosition'),
      language: localStorage.getItem('language'),
      eula: !!localStorage.getItem('eula'),
      welcomeDialog: this.mapWelcomeDialog(user),
      upstartAccountType: this.mapUpstartAccountType(),
    };

    const query = Object.entries(userConfig)
      .filter(([, value]) => value)
      .map(([key, value]) =>
        this.configurationService.savePrivate(key, value).pipe(
          tap(() => localStorage.removeItem(key)),
          catchError(() => of(null)),
        ),
      );
    query.push(of(undefined)); // forkJoin works incorect if query will be empty
    return forkJoin(query).pipe(map(() => ({ ...userConfig, ...configuration })));
  }
}
