import { Inject, Injectable, isDevMode } from '@angular/core';
import {
  AuthenticationType,
  settingsSelectors,
  State,
  User,
  UserRole,
  UserType,
} from '@wam/shared';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiGatewayService, authenticationSelectors, JwtService } from '@wam/authentication';
import { Organization } from '../organizations/organization.model';
import { isNil } from 'lodash-es';
import { WithAuthService } from '../shared/services/with-auth.service';
import * as authenticationActions from '@app/authentication/state/authentication.actions';
import { determineUserType } from '@app/authentication/user.data';

export class UnknownUserTypeError extends Error {
  constructor() {
    super('AuthenticationService: Unknown user type during Cognito settings initialization.');
  }
}

export class FusionAuthIncompatibilityError extends Error {
  constructor() {
    super('AuthenticationService: User type incompatible with Fusion authentication.');
  }
}

enum ProviderNameMap {
  SignInWithApple = 'Apple',
  Google = 'Google',
  Waterford = 'Waterford',
}

type AuthAction = 'login' | 'signup' | 'logout';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService extends WithAuthService {
  private authenticationType$ = this.store.pipe(
    select(authenticationSelectors.getAuthenticationType),
  );

  constructor(
    protected store: Store<State>,
    private http: HttpClient,
    private apiGatewayService: ApiGatewayService,
    private jwtHelperService: JwtService,
    @Inject('window') private window: Window,
  ) {
    super(store);
  }

  logIn() {
    return this.redirectTo('login');
  }

  signUp() {
    return this.redirectTo('signup');
  }

  logOut() {
    return this.redirectTo('logout');
  }

  buildUser(idToken: string, refreshToken: string | undefined, userType: UserType): User {
    const decodedJwt = this.jwtHelperService.decodeToken(idToken);

    const providerName = decodedJwt['identities']?.[0]?.['providerName'] || 'Waterford';

    return {
      idToken,
      refreshToken,
      name: decodedJwt['name'],
      preferredName: decodedJwt['preferredName'],
      cognitoUsername: decodedJwt['cognito:username'],
      type: userType,
      roles: this.decodeRoles(decodedJwt),
      application: this.getApplication(userType),
      uuid: decodedJwt['custom:uuid'],
      organization: decodedJwt['custom:organization'],
      grade: parseInt(decodedJwt['custom:grade'], 10) - 2,
      school: decodedJwt['custom:school'],
      classroom: decodedJwt['custom:class'],
      email: decodedJwt['email'],
      cognitoId: this.getCognitoId(decodedJwt['iss'], decodedJwt['sub']),
      providerName: ProviderNameMap[providerName],
    };
  }

  authorize(code: string): Observable<User> {
    return this.getCognitoSettings().pipe(
      switchMap(({ url, clientId }) =>
        this.http.post<{
          id_token: string;
          refresh_token: string;
        }>(
          `${url}/oauth2/token`,
          new HttpParams()
            .set('grant_type', 'authorization_code')
            .set('client_id', clientId)
            .set('code', code)
            .set('redirect_uri', this.redirectUri()),
        ),
      ),
      mergeMap(({ id_token, refresh_token }) => {
        return this.store.pipe(
          select(settingsSelectors.getUserType),
          map((userType: UserType) => this.buildUser(id_token, refresh_token, userType)),
        );
      }),
      take(1),
    );
  }

  refreshToken(): Observable<User> {
    return combineLatest([
      this.store.pipe(
        select(authenticationSelectors.getRefreshToken),
        filter((token) => localStorage.getItem('skipHome') === 'true' || !isNil(token)),
      ),
      this.getSettings(),
      this.store.select(authenticationSelectors.getAuthenticationType),
    ]).pipe(
      switchMap(([refreshToken, { url, clientId }, authenticationType]) =>
        authenticationType === AuthenticationType.COGNITO
          ? this.refreshCognitoToken(url, clientId, refreshToken)
          : this.refreshFusionToken(url, clientId, refreshToken),
      ),
      mergeMap((token) => {
        return this.store.pipe(
          select(settingsSelectors.getUserType),
          withLatestFrom(this.store.select(authenticationSelectors.getRefreshToken)),
          map(([userType, refreshToken]) => this.buildUser(token, refreshToken, userType)),
        );
      }),
      take(1),
    );
  }

  getReadOnly(organization: string, type?: UserType): Observable<boolean> {
    if (isNil(organization) || type === UserType.STUDENT) {
      return of(true);
    }

    return this.apiGatewayService
      .simpleGet<{ org: Organization }>(`rostering/v2/rostering/source/${organization}`)
      .pipe(map(({ org }) => org.readOnly));
  }

  getOrgCode(organization?: string): Observable<string> {
    return this.withUser().pipe(
      mergeMap((user) =>
        this.apiGatewayService.get<{ orgCode: string }>(
          `user-lookup/v2/auth/tenants/${organization ?? user.organization}/codes`,
        ),
      ),
      map((response) => response.orgCode),
    );
  }

  refreshCognitoToken(
    url: string,
    clientId: string,
    refreshToken: string | undefined,
  ): Observable<string> {
    const params: HttpParams = new HttpParams()
      .set('grant_type', 'refresh_token')
      .set('client_id', clientId)
      .set('refresh_token', refreshToken);
    return this.http
      .post<{ id_token: string }>(`${url}/oauth2/token`, params)
      .pipe(map(({ id_token }) => id_token));
  }

  refreshFusionToken(
    url: string,
    clientId: string,
    refreshToken: string | undefined,
  ): Observable<string> {
    return this.store.pipe(
      select(settingsSelectors.getApiSettings),
      map(({ key }) => key),
      mergeMap((key) =>
        this.http.get<{ data: { attributes: { idToken: string } } }>(
          `${url}/clients/${clientId}/external/refresh/${refreshToken}`,
          { headers: { 'X-Api-Key': key } },
        ),
      ),
      map((response) => response.data.attributes.idToken),
      take(1),
    );
  }

  getCognitoId(issuer: string, subject: string): string {
    if (!issuer || !subject) {
      return '';
    }

    const poolId = issuer.match(/.*\/(.*_.*)/)?.[1] ?? issuer;
    return `${poolId}_${subject}`;
  }

  getSettings(): Observable<{
    url: string;
    clientId: string;
  }> {
    return this.store.pipe(
      select(authenticationSelectors.getAuthenticationType),
      mergeMap((authenticationType: AuthenticationType) =>
        authenticationType === AuthenticationType.COGNITO
          ? this.getCognitoSettings()
          : this.getFusionSettings(),
      ),
    );
  }

  getFusionSettings(): Observable<{
    url: string;
    clientId: string;
  }> {
    return combineLatest([
      this.store.select(settingsSelectors.getFusionSettings),
      this.store.select(authenticationSelectors.getClientId),
    ]).pipe(map(([{ userLookupUrl }, clientId]) => ({ url: userLookupUrl, clientId })));
  }

  getCognitoSettings(action: AuthAction = 'login'): Observable<{
    url: string;
    clientId: string;
  }> {
    return combineLatest([
      this.store.select(settingsSelectors.getSettings),
      this.store.select(settingsSelectors.getUserType),
      this.store.select(settingsSelectors.getUpstartUserLogin),
    ]).pipe(
      map(([settings, userType, upstartUserLogin]) => {
        let url: string;
        let clientId: string;

        let type = userType ?? determineUserType();

        switch (type) {
          case UserType.PARENT:
            url = settings.cognito.userPools.parent.url;
            clientId = settings.cognito.userPools.parent.clientId;

            if (upstartUserLogin) {
              url =
                action === 'login' || action === 'signup'
                  ? settings.cognito.userPools.upstartParent.url
                  : settings.cognito.userPools.parent.url;
              clientId =
                action === 'login' || action === 'signup'
                  ? settings.cognito.userPools.upstartParent.clientId
                  : settings.cognito.userPools.parent.clientId;
            }
            break;
          case UserType.UPSTART:
            url = settings.cognito.userPools.newUpstartParent.url;
            clientId = settings.cognito.userPools.newUpstartParent.clientId;
            break;
          default:
            throw new UnknownUserTypeError();
        }

        return { url, clientId };
      }),
      take(1),
    );
  }

  getFusionUrl(action: AuthAction = 'login'): Observable<string> {
    return combineLatest([
      this.store.select(settingsSelectors.getFusionSettings),
      this.store.select(settingsSelectors.getUserType),
      this.store.select(authenticationSelectors.getCurrentUser),
      this.store.select(authenticationSelectors.getClientId),
    ]).pipe(
      map(([{ loginUrl, adminLoginUrl, userLookupUrl }, userType, user, clientId]) => {
        switch (userType) {
          case UserType.STUDENT:
          case UserType.TEACHER:
          case UserType.ADMIN:
            if (action === 'login' || action === 'signup') {
              return this.getLoginUrl(userType, adminLoginUrl, loginUrl);
            }
            if (action === 'logout') {
              return this.getLogoutUrl(clientId, user, userLookupUrl);
            }
            break;
          default:
            throw new FusionAuthIncompatibilityError();
        }
      }),
      take(1),
    );
  }

  private getLoginUrl(userType: UserType, adminLoginUrl: string, loginUrl: string): string {
    const userLoginUrl = userType === UserType.ADMIN ? adminLoginUrl : loginUrl;
    let target;

    if (/localhost/.test(this.window.location.href)) {
      target = 'local';
    }

    const pullRequestMatch = /\/\/(.*pr-\d+)/.exec(this.window.location.href) ?? [];

    if (pullRequestMatch[1]) {
      target = pullRequestMatch[1];
    }

    const targetUrl = target ? `/?login=new&target=${target}` : '';
    return `${userLoginUrl}${targetUrl}`;
  }

  private getLogoutUrl(clientId: string, user: User, userLookupUrl): string {
    return `${userLookupUrl}/orgs/${clientId}/logout/${user?.idToken}`;
  }

  getApplication(userType: UserType): string {
    return `waterford-${userType.toString().toLowerCase()}`;
  }

  redirectUri(devMode = isDevMode()): string {
    return `${devMode ? 'http' : 'https'}://${this.window.location.host}/logged-in`;
  }

  redirectTo(place: AuthAction) {
    return this.authenticationType$.pipe(
      withLatestFrom(this.store.select(authenticationSelectors.getLogoutUrl)),
      take(1),
      mergeMap(([type, logoutUrl]) => {
        if (place === 'logout' && !isNil(logoutUrl)) {
          return this.store.pipe(
            select(authenticationSelectors.getIdToken),
            withLatestFrom(this.store.select(authenticationSelectors.includeLogoutToken)),
            map(([idToken, includeLogoutToken]) =>
              includeLogoutToken ? `${logoutUrl}?idToken=${idToken}` : logoutUrl,
            ),
            take(1),
          );
        }
        if (type === AuthenticationType.COGNITO) {
          return combineLatest([
            this.getCognitoSettings(place),
            this.store.select(settingsSelectors.getUserType),
            this.store.select(settingsSelectors.isUpstartLoginEnabled),
          ]).pipe(
            take(1),
            map(([{ url, clientId }, settingsUserType, isUpstartLoginEnabled]) => {
              if (settingsUserType === UserType.UPSTART && isUpstartLoginEnabled) {
                const isLocalhost = window.location.href.includes('localhost');
                return `/user-login${isLocalhost ? '?upstart.' : ''}`;
              } else {
                return `${url}/${place}?response_type=code&client_id=${clientId}&redirect_uri=${this.redirectUri()}`;
              }
            }),
          );
        } else {
          return this.getFusionUrl(place);
        }
      }),
      tap((url) => {
        this.window.location.href = url;
      }),
      map(() => authenticationActions.logInActionSuccess()),
    );
  }

  private decodeRoles(decodedJwt): UserRole[] {
    const programType = decodedJwt['custom:prog_type'];
    const isUpstartProgram = programType === 'upstart' || programType?.includes('upstart');

    if (isUpstartProgram) {
      return [UserRole.UPSTART_PARENT];
    }

    return decodedJwt['roles'] ?? [];
  }
}
