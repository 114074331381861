import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '@wam/shared';
import * as authenticationActions from './state/authentication.actions';

@Component({
  selector: 'login',
  template: '',
})
export class LoginComponent implements OnInit {
  constructor(private store: Store<State>) {}

  ngOnInit(): void {
    this.store.dispatch(authenticationActions.logIn());
  }
}
