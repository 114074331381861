import { Injectable } from '@angular/core';
import { settingsSelectors, State, UserType } from '@wam/shared';
import { select, Store } from '@ngrx/store';

import { isNil } from 'lodash-es';
import { filter, map, skipWhile, take, tap } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { WebSocketService } from '@app/shared/web-socket.service';
import * as configurationActions from '@app/configuration/state/configuration.actions';

@Injectable({
  providedIn: 'root',
})
export class OrgUpdatesLockingInitializerService {
  constructor(
    private store: Store<State>,
    private websocketService: WebSocketService,
  ) {}

  initialize(): Observable<boolean> {
    const getSettings$ = this.store.pipe(select(settingsSelectors.getSettings), skipWhile(isNil));
    const userType$ = this.store.pipe(select(settingsSelectors.getUserType));

    return combineLatest([getSettings$, userType$]).pipe(
      map(([, userType]) => [UserType.STUDENT, UserType.TEACHER].includes(userType)),
      tap((isTeacher) => {
        if (isTeacher) {
          this.subscribeToLocked();
          this.subscribeToUnlocked();
        }
      }),
      take(1),
    );
  }

  private subscribeToLocked(): void {
    this.websocketService.connect$
      .pipe(filter(({ message }) => message.startsWith('ORG_UPDATES_LOCKED')))
      .subscribe(() => {
        this.store.dispatch(configurationActions.load());
      });
  }

  private subscribeToUnlocked(): void {
    this.websocketService.connect$
      .pipe(filter(({ message }) => message.startsWith('ORG_UPDATES_UNLOCKED')))
      .subscribe(() => {
        this.store.dispatch(configurationActions.load());
      });
  }
}
