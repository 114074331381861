import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as mentorFormsActions from './mentor-forms.actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { EnrollmentService } from '../enrollment.service';
import { Form, Step } from '@wap/enrollment';
import { of } from 'rxjs';

@Injectable()
export class MentorFormsEffects {
  loadSteps$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mentorFormsActions.loadSteps),
      mergeMap(() =>
        this.enrollmentService.getSteps().pipe(
          map((steps: Step[]) => mentorFormsActions.loadStepsSuccess({ steps })),
          catchError((error) => of(mentorFormsActions.loadStepsFail({ error }))),
        ),
      ),
    ),
  );

  loadForms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mentorFormsActions.loadForms),
      mergeMap(() =>
        this.enrollmentService.getForms().pipe(
          map((forms: Form[]) => mentorFormsActions.loadFormsSuccess({ forms })),
          catchError((error) => of(mentorFormsActions.loadFormsFail({ error }))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private enrollmentService: EnrollmentService,
  ) {}
}
