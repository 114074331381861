import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, tap } from 'rxjs/operators';
import { State } from '@wam/shared';
import * as profileActions from './profile.actions';
import { TranslateService } from '@ngx-translate/core';
import * as feedActions from '@app/feed/state/feed.actions';
import { saveUserConfiguration } from '@app/configuration/state/configuration.actions';
import { Store } from '@ngrx/store';

@Injectable()
export class ProfileEffects {
  toggleTabPosition$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(profileActions.toggleTabPosition),
        tap(({ tabPosition }) =>
          this.store.dispatch(
            saveUserConfiguration({
              key: 'tabPosition',
              value: tabPosition,
            }),
          ),
        ),
      ),
    { dispatch: false },
  );

  updateLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profileActions.updateLanguage),
      tap(({ language }) => this.translateService.use(language)),
      tap(({ language }) => {
        this.store.dispatch(
          saveUserConfiguration({
            key: 'language',
            value: language,
          }),
        );
      }),
      mergeMap(() => [feedActions.clearFeed()]),
    ),
  );

  updateTeacherPIN$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(profileActions.updateTeacherPIN),
        tap(({ pin }) => {
          this.store.dispatch(
            saveUserConfiguration({
              key: 'teacherPIN',
              value: pin,
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private translateService: TranslateService,
    private store: Store<State>,
  ) {}
}
