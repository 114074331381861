import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, take, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';

import { configurationSelectors, State, User, UserType } from '@wam/shared';
import { authenticationSelectors } from '@wam/authentication';

@Injectable({
  providedIn: 'root',
})
export class StudentGuard {
  constructor(
    private store: Store<State>,
    private router: Router,
  ) {}

  canActivate(): Observable<boolean> {
    return combineLatest([
      this.store.pipe(
        select(authenticationSelectors.getCurrentUser),
        map((currentUser: User) => this.isStudent(currentUser)),
      ),
      this.store.pipe(select(configurationSelectors.isEnabled('STUDENT_APP_REDESIGN'))),
    ]).pipe(
      tap(([isStudent, isStudentAppRedesignEnabled]) => {
        if (!isStudent) {
          this.router.navigate(['/']);
        }
        if (isStudentAppRedesignEnabled) {
          this.router.navigate(['/new-student-home']);
        }
      }),
      map(([isStudent, isStudentAppRedesignEnabled]) => isStudent && !isStudentAppRedesignEnabled),
      take(1),
    );
  }

  canLoad(): Observable<boolean> {
    return this.canActivate();
  }

  private isStudent(user: User) {
    return user.type === UserType.STUDENT;
  }
}
