import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, take, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { settingsSelectors, State, UserType } from '@wam/shared';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard {
  constructor(
    private store: Store<State>,
    private router: Router,
  ) {}

  canActivate(): Observable<boolean> {
    return this.store.pipe(
      select(settingsSelectors.getUserType),
      map((userType: UserType) => this.isAdmin(userType)),
      tap((isAdmin: boolean) => {
        if (!isAdmin) {
          this.router.navigate(['/']);
        }
      }),
      take(1),
    );
  }

  canLoad(): Observable<boolean> {
    return this.canActivate();
  }

  private isAdmin(userType: UserType): boolean {
    return userType === UserType.ADMIN;
  }
}
