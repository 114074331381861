import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map, take, withLatestFrom } from 'rxjs/operators';
import { configurationSelectors, State, UserType } from '@wam/shared';
import { authenticationSelectors } from '@wam/authentication';
import { firstValueFrom } from 'rxjs';
import { Theme, ThemingService } from '@wam/theming';

@Injectable({
  providedIn: 'root',
})
export class ThemingInitializerService {
  constructor(
    private store: Store<State>,
    private themingService: ThemingService,
  ) {}

  initialize(): Promise<boolean> {
    return firstValueFrom(
      this.store.pipe(
        select(authenticationSelectors.getCurrentUser),
        withLatestFrom(
          this.store.pipe(select(configurationSelectors.isEnabled('STUDENT_APP_REDESIGN'))),
        ),
        map(([user, isRedesigned]) => {
          if (isRedesigned) {
            this.themingService.theme = Theme.NEW_DESIGN;
            document.body.classList.add('new-design');
            return true;
          }
          if (window.location.href.includes('upstart') || user?.type === UserType.UPSTART) {
            this.themingService.theme = Theme.UPSTART;
            document.body.classList.add('upstart');
            return true;
          }
          return false;
        }),
        take(1),
      ),
    );
  }
}
