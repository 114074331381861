import { Injectable } from '@angular/core';
import { ActionsSubject, Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';
import { State, User } from '@wam/shared';
import { getFromLocalStorage } from './authentication/user.data';
import { Observable, of } from 'rxjs';
import * as authenticationActions from './authentication/state/authentication.actions';
import { ofType } from '@ngrx/effects';
import { isNil } from 'lodash-es';
import { LayoutService } from '@app/layouts/layout.service';

@Injectable({
  providedIn: 'root',
})
export class InitializerService {
  constructor(
    private store: Store<State>,
    private layoutService: LayoutService,
    private actionListener: ActionsSubject,
  ) {
    this.layoutService.initialize();
  }

  load(): Observable<boolean> {
    return this.restoreUser();
  }

  private restoreUser(): Observable<boolean> {
    const isLoggedInPage = window.location.pathname.includes('logged-in');
    const isUpstartLoginPage = window.location.pathname.includes('user-login');
    const user = getFromLocalStorage();

    if (this.userIsAlreadyLoggedIn(isLoggedInPage, user)) {
      this.store.dispatch(authenticationActions.updateCurrentUser({ user, refreshed: true }));

      return this.actionListener.pipe(
        ofType(authenticationActions.logInSuccess, authenticationActions.logOut),
        take(1),
        map(() => true),
      );
    } else if (this.shouldLogIn(isLoggedInPage, isUpstartLoginPage, user)) {
      this.store.dispatch(authenticationActions.logIn());

      return this.actionListener.pipe(
        ofType(authenticationActions.logInActionSuccess),
        take(1),
        map(() => true),
      );
    }

    return of(false);
  }

  private userIsAlreadyLoggedIn(isLoggedInPage: boolean, user: User): boolean {
    return !isLoggedInPage && !isNil(user);
  }

  private shouldLogIn(isLoggedInPage: boolean, isUpstartLoginPage: boolean, user: User): boolean {
    return !(isLoggedInPage || isUpstartLoginPage || user);
  }
}
