import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { reducer } from './state/messages-and-notifications.reducer';
import { EffectsModule } from '@ngrx/effects';
import { MessagesAndNotificationsEffects } from './state/messages-and-notifications.effects';

@NgModule({
  imports: [
    StoreModule.forFeature('messages-and-notifications', reducer),
    EffectsModule.forFeature([MessagesAndNotificationsEffects]),
  ],
})
export class MessagesAndNotificationsStateModule {}
