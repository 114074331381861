import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, race, throwError } from 'rxjs';
import { catchError, map, skip, switchMap, take, tap } from 'rxjs/operators';
import { State } from '@wam/shared';
import { ActionsSubject, select, Store } from '@ngrx/store';
import * as authenticationActions from './authentication/state/authentication.actions';
import { authenticationSelectors } from './authentication/state/authentication.selectors';
import { ofType } from '@ngrx/effects';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;

  constructor(
    private store: Store<State>,
    private actionListener: ActionsSubject,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          !request.url.includes('user-registration') &&
          !request.url.includes('user-lookup') &&
          error.status === 401
        ) {
          return this.handle401Error(request, next);
        } else {
          return throwError(() => error);
        }
      }),
    );
  }

  private addNewToken(request: HttpRequest<unknown>, token: string): HttpRequest<unknown> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private handle401Error(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.store.dispatch(authenticationActions.refreshToken());
    }

    const tokenFailed$ = this.actionListener.pipe(
      ofType(authenticationActions.refreshTokenFailed),
      map(() => throwError(() => new Error('Refresh Token Failed'))),
    );
    const tokenSuccess$ = this.store.pipe(
      select(authenticationSelectors.getIdToken),
      skip(this.isRefreshing ? 1 : 0),
    );

    return race(tokenSuccess$, tokenFailed$).pipe(
      tap(() => (this.isRefreshing = false)),
      take(1),
      switchMap((idToken) => next.handle(this.addNewToken(request, idToken as string))),
      catchError((error) => throwError(() => error)),
    );
  }
}
